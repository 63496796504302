import { Fragment, useEffect } from 'react';
import Busy from './Busy';

function Clock({ onClick, showBusy, debugEnabled }) {
  useEffect(() => {
    const updateClock = () => {
      const clockEl = document.querySelector('#clock');
      if (clockEl) {
        const date = new Date();
        const str = date.toTimeString().slice(0, 5);
        clockEl.innerHTML = str
      }
    }
    const timer = setInterval(updateClock, 1000);
    return () => clearInterval(timer);
  }, [])

  return (
    <div className='w-20 flex flex-row justify-end items-center' onClick={onClick}>
      <Busy showBusy={showBusy}/>
      <p id='clock' className={`f3 no-select ${debugEnabled ? 'red' : ''}`}>00:00</p>
    </div>
  );
}

export default Clock;
