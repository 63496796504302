function Button({ title, disabled, subtitle, chapterIndex, sectionIndex, bold, active, onClick }) {
  const handleClick = () => {
    onClick({ chapterIndex, sectionIndex });
  }
  return (
    <button
      className={`${bold ? 'b' : ''} ${active ? 'bg-red' : ''} white ba b--black tl bg-gray h2-5 f6-5 flex-grow-1 ttu ph2`}
      disabled={!title || disabled}
      onClick={handleClick}>
      <div className={`flex flex-column justify-start items-start lh-copy ${!bold && !active ? 'o-80' : ''}`}>
        <div className=''>{title || ''}</div>
        <div className={`f8 fw1 ${subtitle && subtitle.length ? '' : 'hidden'}`}>{subtitle || 'empty'}</div>
      </div>
    </button>
  );
}

export default Button;
