import Timer from './Timer';
import Speaker from './Speaker';
import Burger from './Burger';
import Volume from './Volume';
import Clock from './Clock';
import Switcher from './Switcher';

function Toolbar({ onCatalogueToggle, onDebug, showBusy, onSwitch, isCollapsed, debugEnabled, contentId, onVolumeChange, volume }) {
  return (
    <header className='flex flex-row justify-between mh4 ph1'>
      <Timer/>
      <Switcher onClick={onSwitch} contentId={contentId}/>
      <Burger onClick={onCatalogueToggle} isCollapsed={isCollapsed}/>
      <Volume onChange={onVolumeChange} volume={volume}/>
      <Clock showBusy={showBusy} onClick={onDebug} debugEnabled={debugEnabled}/>
    </header>
  );
}

export default Toolbar;
