import { useState, useEffect } from 'react';
import { useThrottle } from '@react-hook/throttle'
import toast, { Toaster } from 'react-hot-toast';
import Toolbar from './Toolbar/Toolbar';
import Catalogue from './Catalogue/Catalogue';
import Selector from './Selector/Selector';
import Image from './Image/Image';
import Audio from './Audio/Audio';
import Controls from './Controls/Controls';
import { useNavigate } from "@reach/router"

import utils from '../utils';

function App({ data, contentId, onSwitch, idleImage }) {

  const navigate = useNavigate();

  const [ activeChapterIndex, setActiveChapterIndex ] = useState(0)
  const [ activeSectionIndex, setActiveSectionIndex ] = useState(0);
  const [ activeStopPointIndex, setActiveStopPointIndex ] = useState(0);

  const [ selectedChapterIndex, setSelectedChapterIndex ] = useState(0);
  const [ selectedSectionIndex, setSelectedSectionIndex ] = useState(0);
  const [ selectedStopPointIndex, setSelectedStopPointIndex ] = useState(0);

  const [ stopPointReached, setStopPointReached ] = useState(true);
  const [ playbackInfo, setPlaybackInfo ] = useState(null);//useState(utils.getChapterSectionPlaybackInfo(data.chapters, 0, 0, 0, '0:00:00:00'));
  const [ catalogueCollapsed, setCatalogueCollapsed ] = useState(true);

  const [ volume, setVolume ] = useThrottle(1.0, 10);

  const selectionDiffersFromActive = () => {
    return (activeChapterIndex !== selectedChapterIndex) ||
           (activeSectionIndex !== selectedSectionIndex) ||
           (activeStopPointIndex !== selectedStopPointIndex);
  }

  const handlePlay = async () => {
    const nextIndices = utils.getNextStopPointIndices(data.chapters,
        activeChapterIndex, activeSectionIndex, activeStopPointIndex);
    setActiveChapterIndex(nextIndices.chapterIndex);
    setActiveSectionIndex(nextIndices.sectionIndex);
    setActiveStopPointIndex(nextIndices.stopPointIndex);
    setSelectedChapterIndex(nextIndices.chapterIndex);
    setSelectedSectionIndex(nextIndices.sectionIndex);
    setSelectedStopPointIndex(nextIndices.stopPointIndex);
    const playbackInfo = utils.getChapterSectionPlaybackInfo(data.chapters,
        nextIndices.chapterIndex, nextIndices.sectionIndex,
        nextIndices.stopPointIndex, '0:00:00:00');
    setPlaybackInfo(playbackInfo);
  }

  const handleCatalogueSelect = async ({ chapterIndex, sectionIndex }) => {
    setSelectedChapterIndex(chapterIndex);
    setSelectedSectionIndex(sectionIndex);
    setSelectedStopPointIndex(0);
    setActiveChapterIndex(chapterIndex);
    setActiveSectionIndex(sectionIndex);
    setActiveStopPointIndex(0);
    const playbackInfo = utils.getChapterSectionPlaybackInfo(data.chapters,
        chapterIndex, sectionIndex, 0);
    setPlaybackInfo(playbackInfo);
  }

  const handleCatalogueToggle = () => {
    setCatalogueCollapsed(!catalogueCollapsed);
  }

  const handleSwitch = (route) => {
    if (route == 'r4') {
      navigate('/r4')
    } else if (route == 'v8') {
      navigate('/v8')
    } else if (route == 'jh') {
      navigate('/jh')
    }
  }

  const handleVolumeChange = (volume) => {
    setVolume(volume);
  }

  return (
    <div className='h-100 flex flex-column justify-between overflow-hidden'>
      <div className='flex flex-column justify-between flex-shrink-0 flex-grow-0 overflow-hidden'>
        <Toolbar
          onCatalogueToggle={handleCatalogueToggle}
          onSwitch={handleSwitch}
					contentId={contentId}
          onVolumeChange={handleVolumeChange}
          volume={volume}
          isCollapsed={catalogueCollapsed}
          debugEnabled={false}
          showBusy={stopPointReached == false}
          onDebug={() => {}}
          />
        <Catalogue
          chapters={data.chapters}
          selectedChapterIndex={selectedChapterIndex}
          selectedSectionIndex={selectedSectionIndex}
          isCollapsed={catalogueCollapsed}
          canSelect={true} //stopPointReached}
          onClick={handleCatalogueSelect}
          />
        <Selector
          chapters={data.chapters}
          selectedChapterIndex={selectedChapterIndex}
          selectedSectionIndex={selectedSectionIndex}
          selectedStopPointIndex={selectedStopPointIndex}
          />
      </div>
      <Image
        src={(playbackInfo && playbackInfo.imageSrc) || idleImage}
        paused={stopPointReached}
        />
      <Controls
        canPlay={stopPointReached}
        onPlay={handlePlay}
        />
      <Toaster position='bottom-left'
        toastOptions={{ style: { borderRadius: 0, background: '#272626', color: 'white' }, duration: 8000 }}/>
    </div>
  );
}

export default App;
