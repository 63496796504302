import { useState, useEffect } from 'react';
import { Router, Link } from "@reach/router"

import App from './components/App';

import data_r4 from './data_r4';
import data_jh from './data_jh';
import data_v8 from './data_v8';

const idleJh = './assets/jh/image/0_idle.jpg';
const idleR4 = './assets/r4/image/0_idle.jpg';
const idleV8 = './assets/r4/image/0_idle.jpg';

function Routes() {

  useEffect(() => {
    localStorage.removeItem('debug-enabled');
  }, []);

  return (
		<Router className='w-100 h-100'>
			<App path='/' data={data_v8} contentId={'v8'} idleImage={idleV8}/>
			<App path='v8' data={data_v8} contentId={'v8'} idleImage={idleV8} />
			<App path='r4' data={data_r4} contentId={'r4'} idleImage={idleR4}/>
      {/*<App path='jh' data={data_jh} contentId={'jh'} idleImage={idleJh}/>*/}
		</Router>
  );
}

export default Routes;
