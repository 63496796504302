import ArrowBack from '../../assets/images/arrow_back.svg';
import ArrowFront from '../../assets/images/arrow_front.svg';

function Chevron({ left, right, onClick, active }) {
  return (
    <button className={`${active ? '' : 'hidden'} bg-transparent b--none`}
      onClick={onClick} style={{ width: '3rem', height: '3rem' }}>
      <img src={left ? ArrowBack : ArrowFront} width='100%' height='auto'/>
    </button>
  );
}

export default Chevron;
