import { Fragment, useState, useEffect } from 'react';
import Spinner from '../Video/Spinner';

const Image = ({ src }) =>
{
  const [ activeSrc, setActiveSrc ] = useState(null);
  const [ showSpinner, setShowSpinner ] = useState(true);
  const [ hasError, setHasError ] = useState(false);

  useEffect(async () => {
    if (src) {
      setActiveSrc(null);
      setShowSpinner(true);
      try {
        const res = await fetch(src, { method: 'HEAD' });
        if (!res.ok) {
          setActiveSrc(null);
          setHasError(true);
          setShowSpinner(false);
        } else {
          setActiveSrc(src);
          setShowSpinner(false);
          setHasError(false);
        }
      } catch (_error) {
        setActiveSrc(null);
        setHasError(true);
        setShowSpinner(false);
      }
    } else {
      setActiveSrc(null);
      setHasError(true);
      setShowSpinner(false);
    }
  }, [ src ])

  return (
    <div className={`flex-grow-1 flex-shrink-1`}>
      <div className='flex flex-shrink-1 flex-row items-center justify-center'>
        <img
          src={activeSrc}
          className={`${(hasError || showSpinner || !activeSrc) ? 'dn' : 'dib'} flex-shrink-1 outline-0 b--none z-999`}
          />
      </div>
      <div className={`${showSpinner ? 'flex' : 'dn'} w-100 mt6 flex-column justify-center items-center`}>
        <Spinner/>
      </div>
    </div>
  );
}

export default Image;
