import StopPointActive from '../../assets/images/stoppoint_active.png';
import StopPointPassive from '../../assets/images/stoppoint_passive.png';

function Bullet({ index, selected }) {
  const size = index === selected ? '12px' : '5px';
  return (
    <img src={index === selected ? StopPointActive : StopPointPassive}
      className='mh2 mv2' style={{ height: size, width: size }}/>
  );
}

export default Bullet;
