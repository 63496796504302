import SpeakerOn from '../../assets/images/sprecher.svg';
import SpeakerOff from '../../assets/images/sprecher_off.svg';

function Speaker({ enabled, onClick }) {
  return (
    <div className='w-20 flex flex-row justify-center items-center'>
      <button onClick={onClick} className='b--none bg-transparent pointer'>
        <img src={enabled ? SpeakerOn : SpeakerOff} width='40px' height='40px'/>
      </button>
    </div>
  );
}

export default Speaker;
