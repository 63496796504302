function Switcher({ onClick, contentId }) {

  return (
    <div className='w-20 flex flex-row justify-around items-center'>
			<button
        style={{ borderBottom: (contentId == 'v8' ? '2px solid red' : 'none'),
                 fontWeight: (contentId == 'v8' ? 'bold' : 'normal') }}
        className='pointer white bg-transparent pv2 ph3 f4 b--none'
        onClick={() => onClick('v8')}>
        V8
      </button>
			<button
        style={{ borderBottom: (contentId == 'r4' ? '2px solid red' : 'none'),
                 fontWeight: (contentId == 'r4' ? 'bold' : 'normal') }}
        className='pointer white bg-transparent pv2 ph3 f4 b--none'
        onClick={() => onClick('r4')}>
        R4
      </button>
      {/*
			<button
        style={{ borderBottom: (contentId == 'jh' ? '2px solid red' : 'none'),
                 fontWeight: (contentId == 'jh' ? 'bold' : 'normal') }}
        className='pointer white bg-transparent pv2 ph3 f4 b--none'
        onClick={() => onClick('jh')}>
        JH
      </button>
      */}
    </div>
  );
}

export default Switcher;
