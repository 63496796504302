import { Fragment, useState, useEffect, useRef } from 'react';
import utils from '../../utils';

function Audio({ src, commandTime, onStall, onReady, volume, muted, shouldPlay }) {

  const [ activeSrc, setActiveSrc ] = useState(null);
  const audioRef = useRef();

  useEffect(async () => {
    if (audioRef.current) {
      if (shouldPlay === true) {
        if (audioRef.current.paused === true && audioRef.current.ended === false) {
          try {
            await audioRef.current.play();
          } catch (error) {
            //console.warn(error);
            try {
              audioRef.current.muted = true;
              audioRef.current.setAttribute('muted', 'muted');
              await audioRef.current.play();
            } catch (error) {
            }
          }
        }
      } else if (shouldPlay === false) {
        if (audioRef.current.paused === false) {
          await audioRef.current.pause();
        }
      }
    }
  }, [ shouldPlay ]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [ volume ]);

  useEffect(() => {
    if (audioRef.current) {
      if (muted === false) {
        audioRef.current.muted = false;
        audioRef.current.removeAttribute('muted', 'muted');
      } else {
        audioRef.current.muted = true;
        audioRef.current.setAttribute('muted', 'muted');
      }
    }
  }, [ muted ]);

  useEffect(() => {
    if (src) {
      audioRef.current.src = src;
      audioRef.current.load();
      setActiveSrc(src);

      if (audioRef.current.readyState !== 4) {
        onStall();
      }

      const audioStallCallback = () => {
        onStall();
      }
      
      const audioReadyCallback = () => {
        onReady();
      }

      if (muted === false) {
        audioRef.current.muted = false;
        audioRef.current.removeAttribute('muted', 'muted');
      }

      audioRef.current.addEventListener('stalled', audioStallCallback, false);
      audioRef.current.addEventListener('canplay', audioReadyCallback, false);

      return () => {
        if (audioRef.current) {
          audioRef.current.removeEventListener('stalled', audioStallCallback, false);
          audioRef.current.removeEventListener('canplay', audioReadyCallback, false);
        }
      }
    } else {
      //console.warn('No audio content found');
      audioRef.current.src = null;
      onReady();
    }
  }, [ src, commandTime ])

  return (
    <audio id='audio' ref={audioRef} preload='auto' playsInline className='dn'/>
  );
}

export default Audio;
