import Bullet from './Bullet';

function Chapter({ title, subtitle, active, before, after }) {
  return (
      <div className={`flex flex-column w5 b--light-gray ${before ? 'br' : after ? 'bl' : 'tc'} ${title ? '' : 'hidden'}`} style={{ height: '2.3rem' }}>
        <span className={`ttu dib truncate f5 ph2 mb1 ${before ? 'tr' : after ? 'tl' : 'tc'} ${active ? 'white' : 'light-gray'}`}>
          {title}
        </span>
        <span className={`ttu dib truncate f7 ph2 ${before ? 'tr' : after ? 'tl' : 'tc'} ${active ? 'white' : 'light-gray'}`}>
          {subtitle ? subtitle : ''}
        </span>
      </div>
  );
}

export default Chapter;
