import IdleButton from '../../assets/images/idle_on.png';

import PlayButton from './PlayButton';
import ReplayButton from './ReplayButton';

function Controls({ onPlay, onIdle, canPlay, paused }) {
  return (
    <div className='absolute bottom-x w-100 flex flex-row justify-between flex-shrink-0 pr5 pl4' style={{ zIndex: 9999 }}>
      <button className='hidden b--none bg-transparent pa2 z-999' style={{ width: '6rem', height: '6rem' }} onClick={onIdle}>
        <img src={IdleButton}/>
      </button>
      <PlayButton active={canPlay} paused={paused} onClick={onPlay}/>
      <p className='hidden'>Padding</p>
    </div>
  );
}

export default Controls;
