import Slider from 'rc-slider';
import '../../slider.css';

import Sound from '../../assets/images/sound.svg';
import SoundOff from '../../assets/images/sound.svg';
import utils from '../../utils';

function Volume({ onChange, volume }) {
  const handleChange = (value) => {
    const volume = value / 100.0;
    onChange(volume);
  }

  return (
    <div className='w-30 hidden flex flex-row justify-center items-center'>
      <img className='dib' src={Sound} width='35px' height='35px'/>
      <div className='dib w4'>
        <Slider onChange={handleChange} min={0} max={100} defaultValue={volume * 100}/>
      </div>
    </div>
  );
}

export default Volume;
