const Intro_1_1 = './assets/r4/image/1_Intro_1_1.jpg';
const Intro_2_1 = './assets/r4/image/1_Intro_2_1.jpg';
const Intro_2_2 = './assets/r4/image/1_Intro_2_2.jpg';
const Intro_2_3 = './assets/r4/image/1_Intro_2_3.jpg';
const Intro_2_4 = './assets/r4/image/1_Intro_2_4.jpg';
const Intro_2_5 = './assets/r4/image/1_Intro_2_5.jpg';
const Intro_2_6 = './assets/r4/image/1_Intro_2_6.jpg';
const Intro_2_7 = './assets/r4/image/1_Intro_2_7.jpg';
const Intro_2_8 = './assets/r4/image/1_Intro_2_8.jpg';
const Elektromotor_1_1 = './assets/r4/image/2_Elektromotor_1_1.jpg';
const Elektromotor_2_1 = './assets/r4/image/2_Elektromotor_2_1.jpg';
const Elektromotor_2_2 = './assets/r4/image/2_Elektromotor_2_2.jpg';
const Elektromotor_2_3 = './assets/r4/image/2_Elektromotor_2_3.jpg';
const Elektromotor_2_4 = './assets/r4/image/2_Elektromotor_2_4.jpg';
const Elektromotor_3_1 = './assets/r4/image/2_Elektromotor_3_1.jpg';
const Elektromotor_3_2 = './assets/r4/image/2_Elektromotor_3_2.jpg';
const Batterie_1_1 = './assets/r4/image/3_Batterie_1_1.jpg';
const Batterie_1_2 = './assets/r4/image/3_Batterie_1_2.jpg';
const Batterie_1_3 = './assets/r4/image/3_Batterie_1_3.jpg';
const Batterie_1_4 = './assets/r4/image/3_Batterie_1_4.jpg';
const Batterie_1_5 = './assets/r4/image/3_Batterie_1_5.jpg';
const Batterie_1_6 = './assets/r4/image/3_Batterie_1_6.jpg';
const Batterie_2_1 = './assets/r4/image/3_Batterie_2_1.jpg';
const Batterie_2_2 = './assets/r4/image/3_Batterie_2_2.jpg';
const Batterie_2_3 = './assets/r4/image/3_Batterie_2_3.jpg';
const Batterie_3_1 = './assets/r4/image/3_Batterie_3_1.jpg';
const Batterie_3_2 = './assets/r4/image/3_Batterie_3_2.jpg';
const Batterie_3_3 = './assets/r4/image/3_Batterie_3_3.jpg';
const Motor_1_1 = './assets/r4/image/4_Motor_1_1.jpg';
const Motor_1_2 = './assets/r4/image/4_Motor_1_2.jpg';
const Motor_1_3 = './assets/r4/image/4_Motor_1_3.jpg';
const Motor_2_1 = './assets/r4/image/4_Motor_2_1.jpg';
const Motor_2_2 = './assets/r4/image/4_Motor_2_2.jpg';
const Motor_2_3 = './assets/r4/image/4_Motor_2_3.jpg';
const Motor_2_4 = './assets/r4/image/4_Motor_2_4.jpg';
const Motor_3_1 = './assets/r4/image/4_Motor_3_1.jpg';
const Motor_4_1 = './assets/r4/image/4_Motor_4_1.jpg';
const DrivingDynamics_1_1 = './assets/r4/image/5_DrivingDynamics_1_1.jpg';
const DrivingDynamics_2_1 = './assets/r4/image/5_DrivingDynamics_2_1.jpg';
const DrivingDynamics_2_2 = './assets/r4/image/5_DrivingDynamics_2_2.jpg';
const DrivingDynamics_2_3 = './assets/r4/image/5_DrivingDynamics_2_3.jpg';
const DrivingDynamics_3_1 = './assets/r4/image/5_DrivingDynamics_3_1.jpg';
const DrivingDynamics_3_2 = './assets/r4/image/5_DrivingDynamics_3_2.jpg';
const DrivingDynamics_3_3 = './assets/r4/image/5_DrivingDynamics_3_3.jpg';
const DrivingDynamics_3_4 = './assets/r4/image/5_DrivingDynamics_3_4.jpg';
const DrivingDynamics_3_5 = './assets/r4/image/5_DrivingDynamics_3_5.jpg';
const Extro_1_1 = './assets/r4/image/6_Extro_1_1.jpg';

const data = {
  chapters: [
    [
      {
        title: 'E Performance',
        subtitle: 'Intro',
        controlMessage: 'intro_0',
        image: [
          Intro_1_1
        ],
      },
      {
        title: 'P3 Drivetrain',
        subtitle: 'Overview',
        controlMessage: 'intro_1',
        image: [
          Intro_2_1,
          Intro_2_2,
          Intro_2_3,
          Intro_2_4,
          Intro_2_5,
          Intro_2_6,
          Intro_2_7,
          Intro_2_8,
        ],
      }
    ],
    [
      {
        title: 'Electric Drive Unit',
        subtitle: 'Intro',
        controlMessage: 'edu_0',
        image: [
          Elektromotor_1_1,
        ],
      },
      {
        title: 'Electric Drive Unit',
        subtitle: 'Technology',
        controlMessage: 'edu_1',
        image: [
          Elektromotor_2_1,
          Elektromotor_2_2,
          Elektromotor_2_3,
          Elektromotor_2_4
        ],
      },
      {
        title: 'Electric Drive Unit',
        subtitle: 'In Action',
        controlMessage: 'edu_2',
        image: [
          Elektromotor_3_1,
          Elektromotor_3_2
        ],
      }
    ],
    [
      {
        title: 'Battery',
        subtitle: 'Intro',
        controlMessage: 'battery_0',
        image: [
          Batterie_1_1,
          Batterie_1_2,
          Batterie_1_3,
          Batterie_1_4,
          Batterie_1_5,
          Batterie_1_6
        ],
      },
      {
        title: 'Battery',
        subtitle: 'Technology',
        controlMessage: 'battery_1',
        image: [
          Batterie_2_1,
          Batterie_2_2,
          Batterie_2_3,
        ],
      },
      {
        title: 'Battery',
        subtitle: 'In Action',
        controlMessage: 'battery_2',
        image: [
          Batterie_3_1,
          Batterie_3_2,
          Batterie_3_3,
        ],
      }
    ],
    [
      {
        title: 'R4 Turbo Engine',
        subtitle: 'Intro',
        controlMessage: 'eturbo_0',
        image: [
          Motor_1_1,
          Motor_1_2,
          Motor_1_3
        ],
      },
      {
        title: 'E-Turbo',
        subtitle: 'Tech',
        controlMessage: 'eturbo_1',
        image: [
          Motor_2_1,
          Motor_2_2,
          Motor_2_3,
          Motor_2_4,
        ],
      },
      {
        title: 'RSG',
        subtitle: 'Tech',
        controlMessage: 'eturbo_2',
        image: [
          Motor_3_1
        ],
      },
      {
        title: 'R4 Turbo Engine',
        subtitle: 'In Action',
        controlMessage: 'eturbo_3',
        image: [
          Motor_4_1,
        ],
      }
    ],
    [
      {
        title: 'Driving Dynamics',
        subtitle: 'Intro',
        controlMessage: 'drivingdynamics_0',
        image: [
          DrivingDynamics_1_1
        ],
      },
      {
        title: 'Driving Dynamics',
        subtitle: 'Technology',
        controlMessage: 'drivingdynamics_1',
        image: [
          DrivingDynamics_2_1,
          DrivingDynamics_2_2,
          DrivingDynamics_2_3
        ],
      },
      {
        title: 'Driving Dynamics',
        subtitle: 'In Action',
        controlMessage: 'drivingdynamics_2',
        image: [
          DrivingDynamics_3_1,
          DrivingDynamics_3_2,
          DrivingDynamics_3_3,
          DrivingDynamics_3_4,
          DrivingDynamics_3_5,
        ],
      }
    ],
    [
      {
        title: 'E Performance',
        subtitle: 'Extro',
        controlMessage: 'extro_1',
        image: [
          Extro_1_1
        ],
      }
    ]
  ]
}

export default data;

