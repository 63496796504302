import Spinner from '../Video/Spinner';

function Busy({ showBusy }) {
  return (
    <div className={`${showBusy ? 'db' : 'dn'} w3 h3`}>
      <div className='spinner-small'/>
    </div>
  );
}

export default Busy;
