const isSafariIOS = () => {
  const iOS = window.navigator.userAgent.match(/Safari/);
  return !!iOS && window.navigator.maxTouchPoints > 0;
}

const getPreviousChapterSectionIndices = (chapters, currentChapterIndex, currentSectionIndex) => {
  const previousSectionIndex = currentSectionIndex - 1;
  if (previousSectionIndex >= 0) {
    return {
      chapterIndex: currentChapterIndex,
      sectionIndex: previousSectionIndex
    }
  } else {
    const previousChapterIndex = currentChapterIndex - 1;
    if (previousChapterIndex >= 0) {
      const numSections = chapters[previousChapterIndex].length;
      return {
        chapterIndex: previousChapterIndex,
        sectionIndex: numSections - 1
      }
    } else {
      return null;
    }
  }
}

const getNextChapterSectionIndices = (chapters, currentChapterIndex, currentSectionIndex) => {
  const numSections = chapters[currentChapterIndex].length;
  const nextSectionIndex = currentSectionIndex + 1;
  if (nextSectionIndex < numSections) {
    return {
      chapterIndex: currentChapterIndex,
      sectionIndex: nextSectionIndex
    }
  } else {
    const numChapters = chapters.length;
    const nextChapterIndex = currentChapterIndex + 1;
    if (nextChapterIndex < numChapters) {
      return {
        chapterIndex: nextChapterIndex,
        sectionIndex: 0
      }
    } else {
      return {
        chapterIndex: 0,
        sectionIndex: 0
      }
    }
  }
}

const getPreviousStopPointIndices = (chapters, currentChapterIndex,
    currentSectionIndex, currentStopPointIndex) => {
  const previousStopPointIndex = currentStopPointIndex - 1;
  if (previousStopPointIndex >= 0) {
    return {
      chapterIndex: currentChapterIndex,
      sectionIndex: currentSectionIndex,
      stopPointIndex: previousStopPointIndex
    }
  } else {
    const previousIndices = getPreviousChapterSectionIndices(chapters,
        currentChapterIndex, currentSectionIndex);
    if (previousIndices) {
      const previousSection =
        chapters[previousIndices.chapterIndex][previousIndices.sectionIndex];
      if (previousSection.image) {
        return {
          ...previousIndices,
          stopPointIndex: previousSection.image.length - 1
        };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}

const getNextStopPointIndices = (chapters, currentChapterIndex,
    currentSectionIndex, currentStopPointIndex) => {
  const nextStopPointIndex = currentStopPointIndex + 1;
  const currentSection = chapters[currentChapterIndex][currentSectionIndex];
  if (currentSection.image) {
    const numStopPoints = currentSection.image.length;
    if (nextStopPointIndex < numStopPoints) {
      return {
        chapterIndex: currentChapterIndex,
        sectionIndex: currentSectionIndex,
        stopPointIndex: nextStopPointIndex
      }
    } else {
      const nextIndices = getNextChapterSectionIndices(chapters,
          currentChapterIndex, currentSectionIndex);
      if (nextIndices) {
        const nextSection = chapters[nextIndices.chapterIndex][nextIndices.sectionIndex];
        if (nextSection && nextSection.image) {
          return {
            ...nextIndices,
            stopPointIndex: 0
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  } else {
    return null;
  }
}

const timestampToSeconds = (timestamp) =>
{
  const split = timestamp.split(':');
  if (split.length === 4) {
    const seconds = parseInt(split[2], 10);
    const minutes = parseInt(split[1], 10);
    const hours = parseInt(split[0], 10);
    const result = seconds + minutes * 60 + hours * 60 * 60;
    if (isNaN(result) === false) {
      return result;
    } else {
      console.error('Error generating seconds from Timestamp:', timestamp);
      return null;
    }
  } else {
    console.error('Error generating seconds from Timestamp:', timestamp);
    return null;
  }
}

const getChapterSectionPlaybackInfo = (chapters, chapterIndex,
    sectionIndex, stopPointIndex, timeStampOverride = null) => {
  const section = chapters[chapterIndex][sectionIndex];
  const nextImageSrc = section.image[stopPointIndex];
  const info = {
    imageSrc: nextImageSrc,
    controlMessage: section.controlMessage,
    commandTime: new Date() // To retrigger the video useEffect hook on replay
  }
  return info;
}

export default {
  isSafariIOS,
  getPreviousChapterSectionIndices,
  getNextChapterSectionIndices,
  getPreviousStopPointIndices,
  getNextStopPointIndices,
  timestampToSeconds,
  getChapterSectionPlaybackInfo
};
