import Button from './Button';
import Split from './Split';
import Column from './Column';

function Catalogue({ chapters, selectedChapterIndex, selectedSectionIndex, isCollapsed, onClick, canSelect }) {

  const generateButton = (chapterIndex, sectionIndex, sections, bold) => {
    let active;
    let showSubtitle;
    if (isCollapsed === false) {
      active = (chapterIndex === selectedChapterIndex && sectionIndex === selectedSectionIndex);
      showSubtitle = true;
    } else {
      active = (chapterIndex === selectedChapterIndex);
      showSubtitle = chapterIndex == 0 || chapterIndex == 5;
    }

    const content = sections[sectionIndex];
    return (
      <Button
        disabled={!canSelect}
        chapterIndex={chapterIndex}
        sectionIndex={sectionIndex}
        title={content.title}
        subtitle={showSubtitle && content.subtitle}
        bold={bold}
        active={active}
        onClick={onClick}
        />
    );
  }

  return (
    <nav className='mv4 mh4 overflow-x-auto'>
      <div className='flex flex-row justify-around fixed-width w-960px'>
        {
          isCollapsed === false &&
          chapters.map((sections, chapterIndex) => {
            if (sections.length === 3) {
              return (
                <Column key={chapterIndex}>
                  {generateButton(chapterIndex, 0, sections, true)}
                  {generateButton(chapterIndex, 1, sections)}
                  {generateButton(chapterIndex, 2, sections)}
                </Column>
              );
            } else if (sections.length === 4) {
              return (
                <Column key={chapterIndex}>
                  {generateButton(chapterIndex, 0, sections, true)}
                  <Split>
                    {generateButton(chapterIndex, 1, sections)}
                    {generateButton(chapterIndex, 2, sections)}
                  </Split>
                  {generateButton(chapterIndex, 3, sections)}
                </Column>
              );
            } else if (sections.length === 2) {
              return (
                <Column key={chapterIndex}>
                  {generateButton(chapterIndex, 0, sections, true)}
                  {generateButton(chapterIndex, 1, sections)}
                  <Button/>
                </Column>
              );
            } else if (sections.length === 1) {
              return (
                <Column key={chapterIndex}>
                  {generateButton(chapterIndex, 0, sections, true)}
                  <Button/>
                  <Button/>
                </Column>
              );
            } /*else {
              return (
                <Column key={chapterIndex}>
                  <Button/>
                  <Button/>
                  <Button/>
                </Column>
              );
            }*/
          })
        }
        {
          isCollapsed === true &&
          chapters.map((sections, chapterIndex) => {
            if (sections.length > 0) {
              return (
                <Column key={chapterIndex}>
                  {generateButton(chapterIndex, 0, sections, true)}
                </Column>
              );
            } /*else {
              return (
                <Column key={chapterIndex}>
                  <Button/>
                </Column>
              );
            }*/
          })
        }
      </div>
    </nav>
  );
}

export default Catalogue;
