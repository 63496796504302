import { useEffect, useState } from 'react';
import Rewind from '../../assets/images/reset_time.svg';

function Timer() {
  const [ startTime, setStartTime ] = useState(null);

  useEffect(() => {
    if (startTime != null) {
      const padZero = (num) => {
        if (num < 10) return `0${num}`;
        else return `${num}`;
      }
      const updateTimer = () => {
        const timerMinEl = document.querySelector('#timer-min');
        const timerSecEl = document.querySelector('#timer-sec');
        if (timerMinEl && timerSecEl) {
          const elapsedSecs = Math.floor((new Date().getTime() - startTime.getTime()) / 1000);
          const minutes = Math.floor(elapsedSecs / 60)
          const seconds = elapsedSecs % 60;
          timerMinEl.innerHTML = padZero(minutes);
          timerSecEl.innerHTML = padZero(seconds);
        }
      }
      const timer = setInterval(updateTimer, 500);
      return () => clearInterval(timer);
    }
  }, [ startTime ]);

  useEffect(() => {
      setStartTime(new Date());
  }, []);

  const handleRewind = () =>
  {
    setStartTime(new Date())
    const timerMinEl = document.querySelector('#timer-min');
    const timerSecEl = document.querySelector('#timer-sec');
    if (timerMinEl && timerSecEl) {
      timerMinEl.innerHTML = '00';
      timerSecEl.innerHTML = '00';
    }
  }

  return (
    <div className='w-30 flex flex-row justify-start items-center'>
      <button onClick={handleRewind} className='b--none bg-transparent pointer pl0 pr2'>
        <img src={Rewind} width='35px' height='35px'/>
      </button>
      <div className='flex flex-row items-center'>
        <span id='timer-min' className='f3 ph1'>00</span>
        <span className='f6 ph1 fw1'>Min</span>
        <span id='timer-sec' className='f3 ph1'>00</span>
        <span className='f6 ph1 fw1'>Sek</span>
      </div>
    </div>
  );
}

export default Timer;
