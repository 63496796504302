import Menu from '../../assets/images/menu.svg';
import Fullscreen from '../../assets/images/fullscreen.svg';

function Burger({ onClick, isCollapsed }) {
  return (
    <div className='w-20 flex flex-row justify-center items-center'>
      <button onClick={onClick} className='b--none bg-transparent pv2'>
        <img src={isCollapsed ? Menu : Fullscreen} width='30px' height='30px' />
      </button>
    </div>
  );
}

export default Burger;
