import { useState, useEffect } from 'react';

import PlayActive from '../../assets/images/play_active.svg';
import PlayPassive from '../../assets/images/play_passive.svg';
import PlayPressed from '../../assets/images/play_pressed.svg';

function PlayButton({ type, active, paused, onClick }) {
  const [ pulseState, setPulseState ] = useState(true);
  const [ buttonIcon, setButtonIcon ] = useState(PlayActive);

  useEffect(() => {
    if (paused === true) {
      const pulsate = () => {
        if (pulseState) {
          setButtonIcon(PlayActive);
        } else {
          setButtonIcon(PlayPassive);
        }
        setPulseState(!pulseState);
      }
      const timeout = setTimeout(pulsate, 800);
      return () => clearTimeout(timeout);
    } else {
      setButtonIcon(active ? PlayActive : PlayPassive);
    }
  }, [ active, paused, pulseState ])

  return (
    <button className='b--none bg-transparent pa2 z-999' style={{ width: '6rem', height: '6rem' }} onClick={onClick}>
      <img src={buttonIcon}/>
    </button>
  );
}

export default PlayButton;
