import Chevron from './Chevron';
import Chapter from './Chapter';
import Bullet from './Bullet';
import utils from '../../utils';

function Selector({ chapters, selectedChapterIndex, selectedSectionIndex, selectedStopPointIndex }) {

  const currentSection = chapters[selectedChapterIndex][selectedSectionIndex];
  const currentSectionNumStopPoints = currentSection.image.length;

  let prevSection = null;
  const prevIndices = utils.getPreviousChapterSectionIndices(chapters, selectedChapterIndex, selectedSectionIndex);
  const prevStopPointIndices = utils.getPreviousStopPointIndices(chapters, selectedChapterIndex, selectedSectionIndex, selectedStopPointIndex);
  if (prevIndices) {
    prevSection = chapters[prevIndices.chapterIndex][prevIndices.sectionIndex];
  }

  let nextSection = null;
  const nextIndices = utils.getNextChapterSectionIndices(chapters, selectedChapterIndex, selectedSectionIndex);
  const nextStopPointIndices = utils.getNextStopPointIndices(chapters, selectedChapterIndex, selectedSectionIndex, selectedStopPointIndex);
  if (nextIndices) {
    nextSection = chapters[nextIndices.chapterIndex][nextIndices.sectionIndex];
  }

  return (
    <div className='flex flex-column mb4'>
      <div className='flex flex-row justify-around items-center mh2 ph5 mt1'>
        <Chevron left className='hidden'/>
        <Chapter
          title={prevSection && prevSection.title}
          subtitle={prevSection && prevSection.subtitle}
          before/>
        <Chapter
          title={currentSection.title}
          subtitle={currentSection.subtitle}
          active/>
        <Chapter
          title={nextSection && nextSection.title}
          subtitle={nextSection && nextSection.subtitle}
          after/>
        <Chevron right className='hidden'/>
      </div>
      <div className='flex flex-row justify-center items-center'>
        { 
          currentSectionNumStopPoints > 0 &&
          [...Array(currentSectionNumStopPoints).keys()].map(i => 
            <Bullet key={i} index={i} selected={selectedStopPointIndex}/>
          )
        }
      </div>
    </div>
  );
}

export default Selector;
